import React, { useState } from "react"
import CennikFragment from "./cennikFragment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons"

const CennikSingleRow = (props) => {
  const lista = props.lista
  const [toggle, setToggle] = useState(false)
  const arrowIcon = toggle ? faArrowUp : faArrowDown;

  return (
    <div className="card karta-single-cennik-wrapper">
      <header tabIndex={0} role="button" onClick={() => setToggle(!toggle)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  setToggle(!toggle)
                }
              }} className="card-header karta-single-cennik-header">
        <p className="card-header-title">
          {lista[0][4].tytul}
        </p>
        <a href="#" className="card-header-icon" aria-label="more options">
                <span className="icon">
                  <FontAwesomeIcon
                    style={{ color: "#AB558D" }}
                    icon={arrowIcon}
                  />
                </span>
        </a>
      </header>
      {lista.map(fragment => <CennikFragment h2={fragment[0].h2} th={fragment[1].th}
                                             struktura={fragment[2].struktura}
                                             dodatkowyPodpis={fragment[3].dodatkowyPodpis}
                                             toggle={toggle}/>)}


    </div>
  )
}
export default CennikSingleRow
