import React from "react"
import "bulma/css/bulma.css"
import "../styles/filozofia.scss"
import "../styles/cennik.scss"
import _navigation from "../components/_navigation"
import Footer from "../components/footer"
import cennikStruktura from "../components/cennik-struktura"
import CennikSingleRow from "../components/cennikSingleRow"

const Cennik = () => {
  return (
    <div>
      <_navigation />
      <section className="hero hero-promocje-background">
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title promocje-tytul">Cennik</h1>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="cennik-container">
          {/* Zakładka zabiegi laserowe */}
          <CennikSingleRow
            lista={[
              [
                { h2: "Zamykanie rozszerzonych naczyń krwionośnych" },
                { th: ["Rodzaj", "Cena [PLN] za 1 zabieg"] },
                { struktura: cennikStruktura.zamykanieRozszerzonychNaczynKrwionosnych },
                { dodatkowyPodpis: "* cena ustalana indywidualnie" },
                { tytul: "Zabiegi Laserowe" },
              ],
              [
                { h2: "Przebarwienia" },
                { th: ["Rodzaj", "Cena [PLN] za 1 zabieg"] },
                { struktura: cennikStruktura.przebarwienia },
                { dodatkowyPodpis: "" },
                { tytul: "Zabiegi Laserowe" },
              ],
              [
                { h2: "Usuwanie tatuażu laserem RevLite Q-switched Nd:Yag" },
                { th: ["Rodzaj", "Cena [PLN] za 1 zabieg"] },
                { struktura: cennikStruktura.usuwanieTatuazuLaseremRevLiteQSwitched },
                {
                  dodatkowyPodpis:
                    "* ostateczna cena uzależniona od wielkości tatuażu/ilości przebarwień, ustalana w trakcie konsultacji",
                },
                { tytul: "Zabiegi Laserowe" },
              ],
              [
                { h2: "Odmładzanie/regeneracja skóry laserem RevLite Q-switched Nd:Yag" },
                { th: ["Rodzaj", "Cena [PLN] za 1 zabieg"] },
                { struktura: cennikStruktura.odmladzanieRegeneracjaSkoryLaseremRevLiteQSwitched },
                { dodatkowyPodpis: "** więcej niż 1 obszar jednocześnie - 10% rabat" },
                { tytul: "Zabiegi Laserowe" },
              ],
              [
                { h2: "Usuwanie owłosienia (depilacja laserowa)" },
                { th: ["Rodzaj", "CENA [PLN]* za 1 zabieg DLA PAŃ", "CENA [PLN]* za 1 zabieg DLA PANÓW"] },
                { struktura: cennikStruktura.usuwanieOwlosienia },
                {
                  dodatkowyPodpis:
                    "* 4 i 5 zabieg 30% zniżki od ceny wyjściowej\n" +
                    "   6 i więcej zabiegów 50% zniżki od ceny wyjściowej.",
                },
                { tytul: "Zabiegi Laserowe" },
              ],
              [
                { h2: "Fotoodmładzanie" },
                { th: ["Rodzaj", "CENA [PLN] za 1 zabieg"] },
                { struktura: cennikStruktura.fotoodmladzanie },
                { dodatkowyPodpis: "" },
                { tytul: "Zabiegi Laserowe" },
              ],
              [
                { h2: "Fraxel re:store/Multixel/Exelo 2" },
                { th: ["Rodzaj", "CENA [PLN] za 1 zabieg*"] },
                { struktura: cennikStruktura.fraxelReStoreMultixelExelo2 },
                { dodatkowyPodpis: "" },
                { tytul: "Zabiegi Laserowe" },
              ],
            ]}
          />

          {/* zakładka dermatologia ogólna*/}
          <CennikSingleRow
            lista={[
              [
                { h2: "Dermatologia ogólna" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.dermatologiaOgolna },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia ogólna" },
              ],
              [
                { h2: "Wideodermatoskopia" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.wideodermatoskopia },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia ogólna" },
              ],
            ]}
          />
          {/* zakladka chirurgia plastyczna */}
          <CennikSingleRow
            lista={[
              [
                { h2: "Chirurgia plastyczna" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.chirurgiaPlastyczna },
                { dodatkowyPodpis: "" },
                { tytul: "Chirurgia plastyczna" },
              ]
            ]}
          />

          {/* zakładka dermatologia estetyczna*/}
          <CennikSingleRow
            lista={[
              [
                { h2: "Secret RF" },
                { th: ["Rodzaj", "Cena [PLN] za 1 zabieg"] },
                { struktura: cennikStruktura.secretRF },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Zabiegi przy użyciu własnych tkanek" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.zabiegiPrzyUzyciuWlasnychTkanek },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Mikrodermabrazja" },
                { th: ["Rodzaj", "Cena [PLN] za 1 zabieg"] },
                { struktura: cennikStruktura.mikrodermabrazja },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Hydradermabrazja" },
                { th: ["Rodzaj", "Cena [PLN] za 1 zabieg"] },
                { struktura: cennikStruktura.hydradermabrazja },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Hydroimpact" },
                { th: ["Rodzaj", "Cena [PLN] za 1 zabieg"] },
                { struktura: cennikStruktura.hydroimpact },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Mezoterapia" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.mezoterapia },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Toksyna Botulinowa (Botox, Azzalure, Bocouture)" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.toksynaBotulinowa },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Wypełniacze - kwas hialuronowy" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.wypelniaczeKwasHialuronowy },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Peeling chemiczny" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.peelingChemiczny },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Rewitalizacja skóry" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.rewitalizacjaSkory },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Dermapen" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.dermapen },
                { dodatkowyPodpis: "* zabiegi z użyciem preparatów Derma+ (HA/Stretch Marks/Hair)" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Lampa LED Antyage" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.lampaLEDAntyage },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Osocze bogatopłytkowe (PRP) i fibryna bogatopłytkowa (PRF)" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.osoczePRPiPRF },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Plexr - nieoperacyjny lifting" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.plexr },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "CGF - komórki macierzyste" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.CGFKomorkiMacierzyste },
                {
                  dodatkowyPodpis: "",
                },
                { tytul: "Dermatologia estetyczna" },
              ],
              [
                { h2: "Biostymulatory" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.biostymulatory },
                { dodatkowyPodpis: "" },
                { tytul: "Dermatologia estetyczna" },
              ],
            ]}
          />

          {/* zakładka Zabiegi Dermatochirurgiczne */}
          <CennikSingleRow
            lista={[
              [
                { h2: "Laser CO2" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.laserCO2 },
                { dodatkowyPodpis: "" },
                { tytul: "Zabiegi Dermatochirurgiczne" },
              ],
              [
                { h2: "Chirurgia klasyczna" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.chirurgiaKlasyczna },
                { dodatkowyPodpis: "* w przypadku dodatkowych barwień dopłata" },
                { tytul: "Zabiegi Dermatochirurgiczne" },
              ],
              [
                { h2: "Nowotwory Skóry" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.nowotworySkory },
                { dodatkowyPodpis: "" },
                { tytul: "Zabiegi Dermatochirurgiczne" },
              ],
            ]}
          />

          {/* zakładka sprzedaz kosmetykow */}
          <CennikSingleRow
            lista={[
              [
                { h2: "Revitalash®" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.revitalash },
                { dodatkowyPodpis: "" },
                { tytul: "Kosmetyki - Sprzedaż Detaliczna" },
              ],
              [
                { h2: "Obagi®" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.obagi },
                { dodatkowyPodpis: "" },
                { tytul: "Kosmetyki - Sprzedaż Detaliczna" },
              ],
            ]}
          />

          {/* zakładka kosmetyka lecznicza */}
          <CennikSingleRow
            lista={[
              [
                { h2: "Is CLINICAL" },
                { th: ["Rodzaj", "Cena [PLN]/Czas [MIN.]"] },
                { struktura: cennikStruktura.isClinical },
                { dodatkowyPodpis: "" },
                { tytul: "Kosmetyka Lecznicza" },
              ],
              [
                { h2: "Zabiegi częściowe" },
                { th: ["Rodzaj", "Cena [PLN]/Czas [MIN.]"] },
                { struktura: cennikStruktura.zabiegiCzesciowe },
                { dodatkowyPodpis: "" },
                { tytul: "Kosmetyka Lecznicza" },
              ],
              [
                { h2: "Pozostałe zabiegi" },
                { th: ["Rodzaj", "Cena [PLN]/Czas [MIN.]"] },
                { struktura: cennikStruktura.pozostaleZabiegi },
                { dodatkowyPodpis: "" },
                { tytul: "Kosmetyka Lecznicza" },
              ],
              [
                { h2: "Dodatki do zabiegów*" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.dodatkiDoZabiegow },
                { dodatkowyPodpis: "*tylko i wyłącznie jako dodatek do wykonywanych zabiegów kosmetycznych\n" },
                { tytul: "Kosmetyka Lecznicza" },
              ],
            ]}
          />

          {/* Zakładka Podologia */}
          <CennikSingleRow
            lista={[
              [
                { h2: "" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.podologia },
                { dodatkowyPodpis: "" },
                { tytul: "Podologia" },
              ],
            ]}
          />

          {/* Zakładka Ginekologia Estetyczna */}
          <CennikSingleRow
            lista={[
              [
                { h2: "O-Shot (rewitalizacja okolic intymnych)" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.oShot },
                { dodatkowyPodpis: "" },
                { tytul: "Ginekologia Estetyczna" },
              ],
            ]}
          />

          {/* zakladka Modelowanie sylwetki */}
          <CennikSingleRow
            lista={[
              [
                { h2: "Endermologia LPG Cellu M6 Integral" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.endermologiaLPGCelluM6Integral },
                { dodatkowyPodpis: "" },
                { tytul: "Modelowanie Sylwetki" },
              ],
              [
                { h2: "Ultraskin II" },
                { th: ["Rodzaj", "Cena [PLN] za 1 zabieg*"] },
                { struktura: cennikStruktura.ultraskinII },
                { dodatkowyPodpis: "* ostateczny koszt zależy od powierzchni leczonej okolicy" },
                { tytul: "Modelowanie Sylwetki" },
              ],
              [
                { h2: "i-lipo" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.iLipo },
                { dodatkowyPodpis: "" },
                { tytul: "Modelowanie Sylwetki" },
              ],
              [
                { h2: "i-lipo Ultra (masaż limfatyczny)" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.iLipoUltra },
                {
                  dodatkowyPodpis: "",
                },
                { tytul: "Modelowanie Sylwetki" },
              ],
              [
                { h2: "i-lipo + i-lipo Ultra (masaż limfatyczny)" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.iLipoplusiLipoUltra },
                {
                  dodatkowyPodpis:
                    "* dla pacjentów Centrum Laser-Medica, bezpośrednio po zabiegu lipolizy laserowej inwazyjnej (Velas)\n" +
                    "- zniżka 20%\n" +
                    "** zabieg nie wymaga zakupu dodatkowych kombinezonów",
                },
                { tytul: "Modelowanie Sylwetki" },
              ],
              [
                { h2: "Accent Elite - Lifting" },
                { th: ["Rodzaj", "Cena za 1 zabieg [PLN]", "Cena za 5 zabiegów [PLN]"] },
                { struktura: cennikStruktura.accentEliteLifting },
                { dodatkowyPodpis: "" },
                { tytul: "Modelowanie Sylwetki" },
              ],
              [
                { h2: "Lipoliza iniekcyjna" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.lipolizaIniekcyjna },
                { dodatkowyPodpis: "" },
                { tytul: "Modelowanie Sylwetki" },
              ],
              [
                { h2: "Kriolipoliza CoolTech" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.kriolipolizaCoolTech },
                { dodatkowyPodpis: "" },
                { tytul: "Modelowanie Sylwetki" },
              ],
            ]}
          />

          {/* Zakładka makijaz permanentny */}
          <CennikSingleRow
            lista={[
              [
                { h2: "" },
                { th: ["Rodzaj", "Cena [PLN]"] },
                { struktura: cennikStruktura.makijazPermanentny },
                { dodatkowyPodpis: "UWAGA! Cena dotyczy 1 zabiegu i 1 poprawki" },
                { tytul: "Makijaż Permanentny" },
              ],
            ]}
          />
        </div>
      </section>
      <Footer />
    </div>
  )
}
export default Cennik
