const dermatologiaOgolna = [
  ["Porada lekarska", "250"],
  ["Konsultacja do zabiegu", "150"],
  ["Ocena dermatoskopowa znamion", "300"],
]
const oShot = [
  ["Osocze bogatopłytkowe PRP", "1000"],
  ["Osocze bogatopłytkowe z dodatkiem kwasu hialuronowego", "1500"],
]
const wideodermatoskopia = [
  ["Body Studio Mapowanie ciała (ocena < 20 znamion)", "400"],
  ["Body Studio Mapowanie ciała (ocena > 20 znamion)", "500-600"],
  ["Trichoskopia (badanie włosów)", "300"],
]
const visia = [["Komputerowa analiza skóry twarzy", "150"]]
const laserCO2 = [
  ["Brodawki płaskie", `100/1 szt.-250/do 10 szt. (większa ilość - cena do uzgodnienia)`],
  ['Brodawki zwykłe ("kurzajki")', `od 250/1 szt. (większa ilość - cena do uzgodnienia)`],
  ["Brodawka podeszwowa", `od 300/1 szt. (większa ilość - cena do uzgodnienia)`],
  ["Brodawka łojotokowa", `"250/1 szt. (większa ilość - cena do uzgodnienia)`],
  ["Gruczolaki potowe (powieki)", "250-500"],
  ["Włókniaki miękkie", `200/1 szt. (większa ilość - cena do uzgodnienia)`],
  ["Znamiona skórne", "250/1 szt."],
  ["Grudki perliste", "od 250"],
  ["Grudki (prosaki) Fordyce'a", "od 250"],
  ["Kłykciny kończyste", "od 250 - zależy od ilości zmian"],
  ["Odciski (nagniotki)", "250"],
]
const nowotworySkory = [
  ["Nowotwory skóry", "600-800 (cena uwzględnia badanie histopatologiczne)"],
  ["Inne", "od 500"],
]
const chirurgiaKlasyczna = [
  ["Wycinek diagnostyczny", "300"],
  ["Badanie histopatologiczne (1 zmiany)*", "od 50"],
  ["Mięczaki zakaźne (łyżeczkowanie) < 20 szt.", "od 200-300"],
  ["Mięczaki zakaźne (łyżeczkowanie) > 20 szt", "300-500"],
  ["Znamię skórne", "600-800 (cena uwzględnia badanie histopatologiczne"],
  ["- każde kolejne znamię", "300-400"],
  ["Kaszak, tłuszczak, włókniak twardy", "od 600"],
  ["Keloid (ostrzyknięcie)", "200/1 amp."],
]
const podologia = [
  ["Konsultacja", "bezpłatna"],
  ["Pedicure kosmetyczny", "130 (malowanie + 20)"],
  ["Pedicure medyczny (obcięcie paznokci, usunięcie zrogowaceń)", "150 (malowanie + 20)"],
  ["Pedicure hybrydowy", "150"],
  ["Modzele", "50 /1 szt."],
  ["Obcięcie i opracowanie paznokci zdrowych", "60"],
  ["Oczyszczenie paznokcia zmienionego chorobowego i nadanie kształtu", "30-50 /1 szt"],
  ["Opatrunek", "od 30"],
  ["Opracowanie pękających pięt", "50 /1 pięta"],
  ["Rekonstrukcja płytki paznokcia", "50-70 /1 szt."],
  ["Usuwanie odcisków", "50/1 szt."],
  ["Zabieg parafinowy (peeling + maska + parafina)", "40"],
  ["Opracowanie paznokcia wrastającego", "60/1 szt."],
  ["Założenie klamry", "150"],
  ["Przełożenie klamry", "50"],
]
const endermologiaLPGCelluM6Integral = [
  ["Ciało (40 min)", "200"],
  ["Ciało (40 min) - Seria 5 Zabiegów", "900"],
  ["Ciało (40 min) - Seria 10 Zabiegów", "1700"],
  ["Ciało (40 min) - Seria 15 Zabiegów", "2500"],
  ["Ciało (60 min)", "220"],
  ["Ciało (60 min) - Seria 5 Zabiegów	", "990"],
  ["Ciało (60 min) - Seria 10 Zabiegów", "1800"],
  ["Ciało (60 min) - Seria 15 Zabiegów", "2650"],
  ["Twarz (30 min)", "170"],
  ["Twarz (30 min) - Seria 5 Zabiegów	", "760"],
  ["Twarz (30 min) - Seria 10 Zabiegów", "1500"],
  ["Twarz (30 min) - Seria 15 Zabiegów", "2100"],
  ["Fokus - Pojedynczy mały obszar (np. oczy, biust)", "100"],
  ["Fokus - Pojedynczy duży obszar (np. brzuch, uda)", "120"],
  ["Uniform do zabiegu", "100"],
  ["Uniform do zabiegu", "Przy zakupie karnetu 10 lub 15 zabiegów	GRATIS"],
]
const ultraskinII = [
  ["twarz", "2500-3000"],
  ["szyja", "1500-2000"],
  ["podbródek", "700-900"],
  ["policzki z linią żuchwy", "2000-2200"],
  ["policzki z linią żuchwy i podbródkiem", "2500-3000"],
  ["policzki z linią żuchwy i górną częścią szyi", "2500-3000"],
  ["czoło", "600-800"],
  ["powieki dolne", "500"],
  ["okolica ust", "1000-1200"],
  ["twarz z szyją", "3500-4000"],
  ["dekolt", "2000-2500"],
  ["brzuch", "2000-3000"],
  ["ramiona pow. wewn.", "1500-2000"],
  ["wewn. pow. ud", "2000-3000"],
  ["kolana", "1000-1500"],
  ["pośladki", "3000-3500"],
]

const iLipo = [
  ["1 zabieg", "300"],
  ["pakiet 4 zabiegów", "1100"],
  ["pakiet 8 zabiegów", "2000"],
]

const iLipoUltra = [
  ["1 zabieg", "150"],
  ["pakiet 4 zabiegów", "500"],
  ["pakiet 8 zabiegów", "800"],
]

const iLipoplusiLipoUltra = [
  ["1 zabieg", "400"],
  ["pakiet 4 zabiegów", "1400"],
  ["pakiet 8 zabiegów", "2500"],
]
const accentEliteLifting = [
  ["twarz", "500", "2250"],
  ["owal twarzy (podbródek, tzw. chomiki)", "250", "1100"],
  ["szyja", "300", "1350"],
  ["dekolt", "350", "1600"],
  ["twarz + szyja", "600", "2600"],
  ["ramiona (wewn. powierzchnia tzw. motylki)", "350", "1600"],
  ["brzuch", "500", "2250"],
  ["boczki", "300", "1350"],
  ["pośladki", "500", "2250"],
  ["uda", "600", "2600"],
]

const lipolizaIniekcyjna = [
  ["Prostrolane Inner-B 2ml", "500"],
  ["małe powierzchnie (np podbródek, kark, kolana)", "300-600"],
  ["duże powierzchnie (np brzuch, bryczesy, wewn. pow. ud, ramiona)", "500-1000"],
]
const kriolipolizaCoolTech = [
  ["przyłożenie 1 głowicy", "700"],
  ["przyłożenie 2 głowic", "900"],
]
//makijaz permanentny
const makijazPermanentny = [
  ["Brwi", "800"],
  ["Oczy - powieka górna i dolna", "650"],
  ["Oczy - powieka górna lub dolna", "450"],
  ["Usta z cieniowaniem", "900"],
  ["Usta - całość", "1000"],
  ["Usta - kontur", "700"],
  [`Dodatkowe znieczulenie lignokainą (zabieg wykonuje lekarz)`, "50"],
]

//zabiegi laserowe
const zamykanieRozszerzonychNaczynKrwionosnych = [
  ["Pojedyncze naczynko", "150"],
  ["Cała twarz", "600"],
  ["Czoło", "300"],
  ["Policzki", "400"],
  ["Nos", "200"],
  ["Warga górna", "200"],
  ["Broda", "200"],
  ["Szyja", "200-500"],
  ["Dekolt", "200-500"],
  ["Uda", "*"],
  ["Łydki", "*"],
]
const przebarwienia = [
  ["Cała twarz", "600"],
  ["Czoło", "300"],
  ["Policzki", "400"],
  ["Nos", "200"],
  ["Warga górna", "200"],
  ["Broda", "200"],
  ["Szyja", "300-500"],
  ["Dekolt", "500-600"],
  ["Plecy", "200-800"],
  ["Ramiona", "500-600"],
  ["Przedramiona", "500-600"],
  ["Grzbiety rąk", "300"],
]
const usuwanieTatuazuLaseremRevLiteQSwitched = [
  ["tatuaż czarny", "od 200*"],
  ["tatuaż kolorowy", "od 300*"],
  ["makijaż permanentny - brwi", "400"],
  ["makijaż permanentny - usta", "500"],
  ["zmiany pigmentowe (plamy „starcze”, piegi, ostuda)", "od 300*"],
]

const odmladzanieRegeneracjaSkoryLaseremRevLiteQSwitched = [
  ["peeling laserowy - twarz", "500"],
  ["fotoaging - twarz", "1500**"],
  ["fotoaging - szyja", "1000**"],
  ["fotoaging - dekolt", "1500**"],
  ["fotoaging - grzbiety rąk", "500**"],
  ["blizny potrądzikowe - twarz", "1500"],
  ["blizny potrądzikowe - policzki", "1000"],
  ["rozstępy", "cena ustalana indywidualnie"],
]

const usuwanieOwlosienia = [
  ["Baki", "150-200", "200-250"],
  ["Policzki", "250-300", "250-400"],
  ["Górna warga", "200", "250"],
  ["Broda", "250-300", "350-400"],
  ["Cała twarz", "400-500", "500"],
  ["Szyja", "200-300", "250-400"],
  ["Kark", "150-250", "250-350"],
  ["Pachy", "300", "400"],
  ["Ramiona", "300-400", "400-500"],
  ["Przedramiona", "300-350", "350-450"],
  ["Dłonie", "200-300", ""],
  ["Otoczenie brodawek piersiowych", "150", "100-250"],
  ["Tors (klatka + brzuch)", "500-700", ""],
  ["Plecy", "500-700", ""],
  ["Tułów (tors + plecy)", "1000-1200", ""],
  ["Pośladki", "200-400", "300-450"],
  ["Klatka piersiowa", "400-500", ""],
  ["Brzuch", "300-400", "400-500"],
  ["Linia biała (od pępka do spojenia łonowego)", "150", "200-250"],
  ["Pachwiny", "200", "350"],
  ["Bikini (pachwiny + wzgórek łonowy + linia biała)", "400", ""],
  ["Bikini głębokie (j.w. + wargi sromowe większe)", "500", ""],
  ["Uda", "550-650", "600-800"],
  ["Łydki", "500", "500-700"],
  ["Stopy", "100-200", "200"],
  ["Całe nogi", "1000-1100", ""],
  ["Całe nogi + bikini", "1100-1300", ""],
]
const fotoodmladzanie = [
  ["Cała twarz", "600"],
  ["Szyja", "300-500"],
  ["Dekolt", "500-600"],
  ["Grzbiety rąk", "400"],
  ["Plecy (góra)", "500-600"],
]

const fraxelReStoreMultixelExelo2 = [
  ["Cała twarz", "1500-1700"],
  ["Szyja", "1000"],
  ["Dekolt", "1200-1500"],
  ["Twarz, szyja, dekolt", "2500"],
  ["Policzki", "1000"],
  ["Okolica oczu", "300-400"],
  ["Okolica ust", "300-400"],
  ["Czoło", "500"],
  ["Grzbiety rąk", "500"],
  ["Plecy, brzuch", "cena ustalana indywidualnie w zależności od powierzchni"],
  ["Pojedyncze blizny, rozstępy", "cena ustalana indywidualnie w zależności od powierzchni"],
]
const exuviance = [
  ["Zabieg do skóry naczyniowej/ trądzik różowaty	", "250-300/ 60 min"],
  ["Zabieg do skóry suchej i wrażliwej", "250-300/ 60 min"],
  ["Zabieg do skóry tłustej i mieszanej", "250-300/ 60 min"],
  ["Zabieg rozświetlający okolice oczu", "180/ 40 min"],
]

const isClinical = [
  ["Zabieg odmładzający „ogień i lód”", "250/ 40min"],
  ['Zabieg „kompleksowe odświeżenie"', "250/ 40min"],
  ["Zabieg „promienne oczy”", "180/ 40 min"],
]

const zabiegiCzesciowe = [
  ["Mikrodermabrazja + ultradźwięki z ampułką (twarz)", "300/ 40min"],
  ["Twarz + szyja", "350"],
  ["Twarz + szyja + dekolt", "400"],
  ["Mikrodermabrazja + maska algowa", "200/40min."],
  ["Ultradźwięki z ampułką", "150/20min."],
  ["Ultradźwięki z ampułką + maska algowa", "180/40min."],
  ["Peeling kawitacyjny", "150/20min."],
  ["Peeling kawitacyjny z ampułką", "180/30min."],
]

const pozostaleZabiegi = [
  ["Cosmelan (peeling na przebarwienia) - maska + Cosmelan 2 + Hydra Vital Factor", "1500"],
  ["Cosmelan 2 krem rozjaśniający 30g", "650"],
  ["Dermamelan - maska + Dermamelan 2 krem + Melan recovery + Melan 130 krem przeciw słoneczny)", "1500"],
  ["Dermamelan 2 krem", "750"],
  ["Melan recovery krem", "250"],
  ["Zabieg przeciwtrądzikowy", "200-250"],
]

const dodatkiDoZabiegow = [
  ["Masaż manualny (twarz)", "30-50"],
  ["Henna (brwi)", "10"],
  ["Henna (rzęs)", "10"],
  ["Regulacja brwi", "10"],
  ["Henna (brwi i rzęs z regulacją)", "30"],
]
//dermatologia estetyczna
const secretRF = [
  ["Twarz", "1500"],
  ["Szyja", "1000"],
  ["Dekolt", "1200-1500"],
  ["twarz + szyja", "2000"],
  ["twarz + szyja + dekolt", "2500"],
  ["piersi", "1200"],
  ["nadpotliwość (pachy)", "1200"],
  ["rozstępy, blizny", "cena do uzgodnienia po konsultacji"],
  ["poj. obszary na ciele", "cena do uzgodnienia po konsultacji"],
]
const zabiegiPrzyUzyciuWlasnychTkanek = [["Regenera Activa", "6000"]]
const mikrodermabrazja = [
  ["Twarz", "200"],
  ["Twarz + Szyja", "250"],
  ["Dekolt", "150"],
  ["twarz + szyja + dekolt", "350"],
  ["Ramiona", "150"],
  ["Przedramiona", "150"],
  ["Dłonie", "150"],
  ["Pośladki", "200-250"],
]
const hydradermabrazja = [
  ["Twarz", "250"],
  ["Twarz + Szyja", "300"],
  ["twarz + szyja + dekolt", "400"],
  ["Okolica ust (głębsze złuszczanie)", "200"],
  ["Rozstępy", "Cena uzależniona od powierzchni"],
  ["Blizny potrądzikowe", "150-300"],
  ["Mezoterapia bezigłowa", "+ 150 do zabiegu"],
]
const hydroimpact = [
  ["Oczyszczanie wodorowe", "300"],
  ["Kompletna terapia anty-aging", "500"],
]

const mezoterapia = [
  ["Twarz (RRS Hyalift, RRS TensorLift, NCTF HA)", "400"],
  ["Twarz + szyja", "500"],
  ["Twarz + szyja + dekolt", "600"],
  ["Cellulit", "300-500"],
  ["Łysienie-koktajle", "300"],
  ["Łysienie-Hairfiller dr Cyj", "500"],
  ["Łysienie-osocze bogatopłytkowe", "800"],
  ["Dermal WM", "100 PLN do każdego zabiegu mezoterapi"],
]
const toksynaBotulinowa = [
  ['Zmarszczki międzybrwiowe ("lwie")', "500"],
  ["Zmarszczki poprzeczne czoła", "400-500"],
  ["Zmarszczki (całe czoło)", "800"],
  ['Zmarszczki wokół oczu ("kurze łapki")', "500"],
  ["Zmarszczki całe czoło+kurze łapki", "1200"],
  ["Bruksizm", "600-1000"],
  ["Nadpotliwość: pachy", "1500"],
  ["Nadpotliwość: dłonie", "1600"],
  ["Nadpotliwość: stopy", "1600"],
]
const wypelniaczeKwasHialuronowy = [
  [
    "Klasyczne wypełniacze - „duża” ampułka:\n" +
      "Teosyal (Deep Lines, Global Action, Ultra Deep) – 1 ml\n" +
      "Stylage (M, L) – 1 ml\n",
    "1100 /amp.\n" + "+ 600 każda kolejna amp.",
  ],
  ["Wypełnianie ust:\n" + "Teosyal Kiss – 1 ml\n" + "Stylage Lips – 1 ml\n" + "Stylage M 1 ml", "1200 /amp.\n"],
  ["Wypełnianie ust: Teosyal RHA Kiss – 0,7 ml", "800/amp."][
    ("Wolumetria:\n" + "Teosyal Ultimate – 1 ml\n" + "Stylage XL – 1 ml", "1200 /amp. + 600 każda kolejna amp.")
  ],
  [
    "Mezolift: Teosyal Redensity I – 3 ml, Neauvia HydroDelux - 2,5 ml, Stylage HydroMax – 1 ml",
    "1200 /amp.\n" + "1000 /amp.\n" + "800 /amp.\n",
  ],
]

const peelingChemiczny = [
  ["Kwas glikolowy (AHA)", "200"],
  ["Kwas trójchlorooctowy (TCA): twarz", "500"],
  ["Płyn Jessnera", "200"],
  ["Peeling pirogronowy (Mediderma)", "200"],
  ["Cosmelan (peeling na przebarwienia) - maska + Cosmelan 2 + Hydra Vital Factor", "1500"],
  ["Cosmelan 2 krem rozjaśniający 30g", "650"],
  ["Hydra Vital Factor krem odżywczy", "250"],
  ["Mandelac (kwas migdałowy)", "200"],
  ["Salipeel (kwas salicylowy)", "200"],
]

const rewitalizacjaSkory = [
  ["Mikrodermabrazja + peeling", "300"],
  ["Mikrodermabrazja + mezoterapia (NCTF HA/RRS Hyalift/RRS TensorLift)", "450"],
  ["Peeling kawitacyjny + mezoterapia (NCTF HA/Dermaheal HSR)", "450"],
  ["Peeling + mezoterapia (NCTF HA/Dermaheal HSR)", "450"],
  ["Mezolift + ultradźwięki", "650"],
]

const dermapen = [
  ["Twarz", "600"],
  ["Szyja", "600"],
  ["Dekolt", "600"],
  ["Ręce", "500"],
  ["Okolica oczu", "350"],
  ["Okolica ust", "350"],
  ["Rozstępy", "cena ustalana w zależności od powierzchni"],
  ["Pixel Peel - twarz + Easy TCA", "800"],
  ["Łysienie", "400"],
]

const lampaLEDAntyage = [
  ["1 zabieg", "100"],
  ["1 zabieg bezpośrednio po innej procedurze", "50"],
  ["Seria 4 zabiegów", "320"],
  ["Seria 8 zabiegów", "480"],
]
// wczesniej regeneris
const osoczePRPiPRF = [
  ["PRP Classic, PRF Classic (mezoterapia twarzy, łysienie)", "800"],
  ["Plasma Complex - fibryna strukturalna i komórkowa", "1200"],
  ["Cellular Matrix - osocze bogatopłytkowe + kwas hialuronowy", "1200"],
  ["PRP Extra (mezoterapia większych powierzchni, np twarz + szyja + dekolt)", "1300"],
]
const plexr = [
  ["Powieki górne", "1000"],
  ["Powieki dolne", "1000"],
  ["Powieki górne i dolne", "1500"],
  ["Zmarszczki ok. uszu", "800"],
  ["Zmarszczki palacza", "800"],
  ["Lifting szyi", "1000"],
  ["Blizny", "cena zależy od wielkości obszaru"],
  [
    "Zabiegi małej chirurgii (brodawki płaskie, brodawki łojotokowe, włókniaki, naczyniaki itp.)",
    "100-300/pojedyncza zmiana",
  ],
]

const CGFKomorkiMacierzyste = [
  ["CGF Harmony Twarz", "1500"],
  ["CGF Harmony twarz, szyja, dekolt", "1800"],
]

const revitalash = [
  ["RevitaLash® Eyelash Conditioner Advanced", "2 ml - 250 \n 3,5 ml - 330"],
  ["RevitaLash® Volumizing Mascara", "90"],
  ["RevitaLash® RevitaBrow Advanced", "270"],
  ["RevitaLash® Perfect Primer", "70"],
  ["RevitaLash® Spotlight Highlighting Pencil", "70"],
  ["Nouriche™ Eyelash Conditioner", "240"],
]

const obagi = [
  ["ElastiDerm Eye Complete Complex Serum 14 ml", "300"],
  ["Professional C-serum 10% 30 ml", "200"],
  ["Professional C-serum 15% 30 ml", "250"],
  ["Professional C-serum 20% 30 ml", "300"],
  ["Hydrate Facial Moisturizer 48g", "120"],
  ["Sun Shield Matte Broad Spectrum SPF 50 85g", "130"],
  ["Nu-Derm System*", "1500"],
  ["Gentle Cleanser", "130"],
  ["Foaming Gel", "130"],
  ["Obagi Toner", "130"],
  ["Clear Fx krem rozjaśniający na dzień i na noc", "300"],
  ["Exfoderm/Exfoderm forte", "250"],
  ["Blend Fx krem rozjaśniający na noc", "300"],
  ["Hydrate krem nawilżający na dzień i na noc", "130"],
  ["Sun Shield SPF 50 Matte \n *do zestawu dołączona tretinoina w kremie", "130"],
  ["C-FX System", "1200"],
  ["C-Cleansing Gel - żel oczyszczający", "120"],
  ["C-Balancing Toner", "120"],
  ["C-Clarifying Serum na dzień", "350"],
  ["C-Exfoliating Day Lotion", "250"],
  ["C-Therapy Night Cream", "300"],
  ["Serum pod oczy ELASTIderm", "300"],
  ["ELASTIderm Eye Treatment Cream", "250"],
  ["Serum Professional-C 10%", "200"],
  ["Serum Professional-C 15%", "250"],
  ["Serum Professional-C 20%", "300"],
]
const biostymulatory = [
  ["EJAL40", "1000/1 amp. a 2ml"],
  ["GOURI", "1500/1 amp. a 1ml"],
  ["HARMONYCA", "2500/2 amp. a 1,25ml"],
  ["KARISMA", "1200/1 amp. a 2ml"],
  ["NUCLEOFILL STRONG/SOFT", "1200/1 amp. a 1,5ml / 1000/1 amp. A 2ml"],
  ["REVOK50", "1000/1 amp. a 2ml"],
  ["TROPOKOLAGEN GUNA", "400/1 amp. A 2ml"],
  ["RRS HA Long Lasting", "1200/1 amp. a 3ml"],
  ["SCULPTRA (kwas polimlekowy)", "1800/1 fiol."],
]
const chirurgiaPlastyczna = [
  ["Plastyka powiek górnych", "5000"],
  ["Plastyka powiek dolnych", "6500"],
  ["Plastyka uszu", "5000"],
  ["Labioplastyka (plastyka warg sromowych mniejszych)", "5000"],
  ["Chirurgiczna korekta blizn", "od 500"],
]
export default {
  revitalash,
  obagi,
  hydroimpact,
  toksynaBotulinowa,
  hydradermabrazja,
  mikrodermabrazja,
  zabiegiPrzyUzyciuWlasnychTkanek,
  mezoterapia,
  wypelniaczeKwasHialuronowy,
  rewitalizacjaSkory,
  peelingChemiczny,
  lampaLEDAntyage,
  dermapen,
  osoczePRPiPRF,
  plexr,
  CGFKomorkiMacierzyste,
  laserCO2,
  chirurgiaKlasyczna,
  nowotworySkory,
  visia,
  dermatologiaOgolna,
  wideodermatoskopia,
  podologia,
  endermologiaLPGCelluM6Integral,
  ultraskinII,
  iLipo,
  iLipoUltra,
  iLipoplusiLipoUltra,
  accentEliteLifting,
  lipolizaIniekcyjna,
  kriolipolizaCoolTech,
  makijazPermanentny,
  zamykanieRozszerzonychNaczynKrwionosnych,
  przebarwienia,
  usuwanieTatuazuLaseremRevLiteQSwitched,
  odmladzanieRegeneracjaSkoryLaseremRevLiteQSwitched,
  usuwanieOwlosienia,
  fotoodmladzanie,
  fraxelReStoreMultixelExelo2,
  exuviance,
  isClinical,
  zabiegiCzesciowe,
  pozostaleZabiegi,
  dodatkiDoZabiegow,
  secretRF,
  biostymulatory,
  chirurgiaPlastyczna,
  oShot
}
