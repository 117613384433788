import React from "react"
import "bulma/css/bulma.css"
import "../styles/filozofia.scss"
import "../styles/cennik.scss"

const CennikFragment = ({ h2, th, struktura, dodatkowyPodpis, toggle }) => {
  return (
    <React.Fragment>
      <div className={`card-content ${toggle ? "is-active" : "is-not-active"}`}>
        <div className="content">
          <h2>{h2}</h2>
          <table className="table">
            <thead>
              <tr>
                {th.map((header, id) => (
                  <th className={`${id === 0 ? "rodzaj-col" : ""}`}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {struktura &&
                struktura.map(row => (
                  <tr>{row && row.map((td, id) => <td className={`${id === 0 ? "pierwszy-td" : ""}`}>{td}</td>)}</tr>
                ))}
            </tbody>
          </table>
          <span>{dodatkowyPodpis}</span>
        </div>
      </div>
    </React.Fragment>
  )
}
export default CennikFragment
